/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import environment from '../../environments';
import HttpErrorResponseModel from '../../models/HttpErrorResponseModel';
import HttpUtility from '../../utilities/HttpUtility';
import ProfileModel from './models/ProfileModel';
import WebAnalyticsService from '../../services/WebAnalyticsService';

export default class ProfileEffect {
  static async getProfile() {
    const endpoint = `${environment.BACKEND_URL}/api/candidates/screening-candidate`;

    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    let file = {};
    if (response.data.profile && response.data.profile.resumeFile) {
      file = response.data.profile.resumeFile;
    }

    let profilePictureURL = '';
    if (
      response.data.profile &&
      response.data.profile.croppedProfilePictureUrl
    ) {
      profilePictureURL = response.data.profile.croppedProfilePictureUrl;
    }

    return {
      profile: new ProfileModel(response.data.profile),
      file,
      profilePictureURL,
    };
  }

  static async saveProfile(data) {
    const params = new URLSearchParams(
      WebAnalyticsService.getUtmData(),
    ).toString();
    const endpoint = `${environment.BACKEND_URL}/api/candidates/screening-candidate?${params}`;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const updatedData = new FormData();
    const jsonData = {};
    for (const key in data) {
      if (key === 'resumeFile') {
        updatedData.append('resume', data[key][0]);
      } else {
        jsonData[key] = data[key];
      }
    }
    updatedData.append('data', JSON.stringify(jsonData));
    const response = await HttpUtility.post(endpoint, updatedData, headers);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    let file = {};
    if (response.data.profile && response.data.profile.resumeFile) {
      file = response.data.profile.resumeFile;
    }

    return {
      profile: new ProfileModel(response.data.profile),
      file,
    };
  }
}
