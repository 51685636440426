/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, change } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import DragList from '../../../shared/components/form/DragList';
import validate from './validate';
import config from '../../../shared/config';

const WizardFormThree = (props) => {
  const { handleSubmit, previousPage, dispatch, initialValues } = props;

  const [importantGoals, setImportantGoals] = useState(
    config.surveyConfig.rankedQuestionsOptions.importantGoals,
  );

  const updateImportantGoals = (data) => {
    setImportantGoals(data);
    dispatch(change('userInfo', 'user_value__importantGoals', data));
  };

  const [preferredWorkType, setPreferredWorkType] = useState(
    config.surveyConfig.rankedQuestionsOptions.preferredWorkType,
  );

  const updatePreferredWorkType = (data) => {
    setPreferredWorkType(data);
    dispatch(change('userInfo', 'user_value__preferredWorkType', data));
  };

  const [managingEmployeeProblems, setManagingEmployeeProblems] = useState(
    config.surveyConfig.rankedQuestionsOptions.managingEmployeeProblems,
  );

  const updateManagingEmployeeProblems = (data) => {
    setManagingEmployeeProblems(data);
    dispatch(change('userInfo', 'user_value__managingEmployeeProblems', data));
  };

  const [workHabits, setWorkHabits] = useState(
    config.surveyConfig.rankedQuestionsOptions.workHabits,
  );

  const updateWorkHabits = (data) => {
    setWorkHabits(data);
    dispatch(change('userInfo', 'user_value__workHabits', data));
  };

  const [checkIns, setCheckIns] = useState(
    config.surveyConfig.rankedQuestionsOptions.checkIns,
  );

  const updateCheckIns = (data) => {
    setCheckIns(data);
    dispatch(change('onboardingSurvey', 'user_value__checkIns', data));
  };

  const [workProgress, setWorkProgress] = useState(
    config.surveyConfig.rankedQuestionsOptions.workProgress,
  );

  const updateWorkProgress = (data) => {
    setWorkProgress(data);
    dispatch(change('userInfo', 'user_value__workProgress', data));
  };

  useEffect(() => {
    if (
      Array.isArray(initialValues.user_value__importantGoals) &&
      initialValues.user_value__importantGoals.length > 0
    ) {
      dispatch(
        change(
          'userInfo',
          'user_value__importantGoals',
          initialValues.user_value__importantGoals,
        ),
      );
      setImportantGoals(initialValues.user_value__importantGoals);
    } else {
      dispatch(
        change('userInfo', 'user_value__importantGoals', importantGoals),
      );
    }

    if (
      Array.isArray(initialValues.user_value__managingEmployeeProblems) &&
      initialValues.user_value__managingEmployeeProblems.length > 0
    ) {
      dispatch(
        change(
          'userInfo',
          'user_value__managingEmployeeProblems',
          initialValues.user_value__managingEmployeeProblems,
        ),
      );
      setManagingEmployeeProblems(
        initialValues.user_value__managingEmployeeProblems,
      );
    } else {
      dispatch(
        change(
          'userInfo',
          'user_value__managingEmployeeProblems',
          managingEmployeeProblems,
        ),
      );
    }

    if (
      Array.isArray(initialValues.user_value__workHabits) &&
      initialValues.user_value__workHabits.length > 0
    ) {
      dispatch(
        change(
          'userInfo',
          'user_value__workHabits',
          initialValues.user_value__workHabits,
        ),
      );
      setWorkHabits(initialValues.user_value__workHabits);
    } else {
      dispatch(change('userInfo', 'user_value__workHabits', workHabits));
    }

    if (
      Array.isArray(initialValues.user_value__workProgress) &&
      initialValues.user_value__workProgress.length > 0
    ) {
      dispatch(
        change(
          'userInfo',
          'user_value__workProgress',
          initialValues.user_value__workProgress,
        ),
      );
      setWorkProgress(initialValues.user_value__workProgress);
    } else {
      dispatch(change('userInfo', 'user_value__workProgress', workProgress));
    }
    if (
      Array.isArray(initialValues.user_value__preferredWorkType) &&
      initialValues.user_value__preferredWorkType.length > 0
    ) {
      dispatch(
        change(
          'userInfo',
          'user_value__preferredWorkType',
          initialValues.user_value__preferredWorkType,
        ),
      );
      setPreferredWorkType(initialValues.user_value__preferredWorkType);
    } else {
      dispatch(
        change('userInfo', 'user_value__preferredWorkType', preferredWorkType),
      );
    }

    if (
      Array.isArray(initialValues.user_value__checkIns) &&
      initialValues.user_value__checkIns.length > 0
    ) {
      dispatch(
        change(
          'userInfo',
          'user_value__checkIns',
          initialValues.user_value__checkIns,
        ),
      );
      setCheckIns(initialValues.user_value__checkIns);
    } else {
      dispatch(change('userInfo', 'user_value__checkIns', checkIns));
    }
  }, []);

  return (
    <form
      className="form form--horizontal wizard__form wizard__form__custom"
      onSubmit={handleSubmit}
    >
      <div className="form__form-group">
        <div className="wizard__form__custom__label">
          <span className="wizard__form__label__custom wizard__bold__title">
            The following are all important goals, but if you had to choose
            among them given limited time and resources, how do you prioritize
            the following, from most important (1) to least important (5)?
          </span>
        </div>
      </div>
      <DragList items={importantGoals} updateItems={updateImportantGoals} />

      <div className="form__form-group">
        <div className="wizard__form__custom__label">
          <span className="wizard__form__label__custom wizard__bold__title">
            What types of work do you prefer? Rank them from favorite (1) to
            least favorite (2)
          </span>
        </div>
      </div>
      <DragList
        items={preferredWorkType}
        updateItems={updatePreferredWorkType}
      />

      <div className="form__form-group">
        <div className="wizard__form__custom__label">
          <span className="wizard__form__label__custom wizard__bold__title">
            You’ve been a star employee for your company. However, the constant
            pressure of meeting deadlines has been increasing, so much so that
            it is becoming more difficult to keep up with the pace of your team.
            At this point, how would you rank the following strategies for
            handling this situation, from the strategy you are most likely to
            use (1) to the strategy you are least likely to use (4)?
          </span>
        </div>
      </div>
      <DragList
        items={managingEmployeeProblems}
        updateItems={updateManagingEmployeeProblems}
      />

      <div className="form__form-group">
        <div className="wizard__form__custom__label">
          <span className="wizard__form__label__custom wizard__bold__title">
            Which of the following attributes best describes you, from describes
            you best (1) to describes you least (4)?
          </span>
        </div>
      </div>
      <DragList items={workHabits} updateItems={updateWorkHabits} />

      <div className="form__form-group">
        <div className="wizard__form__custom__label">
          <span className="wizard__form__label__custom wizard__bold__title">
            Which of the following better describes you? Rank the choices from
            more important (1) to less important (2)
          </span>
        </div>
      </div>
      <DragList items={checkIns} updateItems={updateCheckIns} />

      <div className="form__form-group">
        <div className="wizard__form__custom__label">
          <span className="wizard__form__label__custom wizard__bold__title">
            In terms of reporting your progress to your supervisor, which do you
            prefer? Rank the choices from your highest preference (1) to your
            lowest preference (3)
          </span>
        </div>
      </div>
      <DragList items={workProgress} updateItems={updateWorkProgress} />

      <ButtonToolbar className="form__button-toolbar wizard__toolbar">
        <Button
          color="primary"
          type="button"
          className="previous wizard__form__button__custom"
          onClick={previousPage}
        >
          Back
        </Button>
        <Button
          color="primary"
          type="submit"
          className="next wizard__form__button__custom"
        >
          Submit
        </Button>
      </ButtonToolbar>
    </form>
  );
};

WizardFormThree.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'userInfo', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WizardFormThree);
