import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';

import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import UserInfoSurveyForm from '../UserInfoSurvey';
import Loading from '../../shared/components/Loading';
import NotfoundPage from '../Notfound';

const onboardingRoute = () => (
  <div>
    <Layout />
    <Route path="/notfound" component={NotfoundPage} />
    <Route path="/" exact component={UserInfoSurveyForm} />
  </div>
);

const Router = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <MainWrapper>
      <main>
        {promiseInProgress && <Loading loading={promiseInProgress} />}
        <Switch>
          <Route path="/" component={onboardingRoute} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
