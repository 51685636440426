import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';

const RadioButtonGroup = ({ className, items, meta }) => (
  <div className="radio-inline-align-custom onboarding__radio__buttons">
    <div className="radio-options-list">
      {items.map((item) => (
        <div key={`index_${item.label}`}>
          <Field
            name={`${item.name}`}
            component={renderRadioButtonField}
            label={item.label}
            radioValue={item.radioValue}
            defaultChecked={item.defaultChecked}
            disabled={item.disabled}
            className={className}
          />
        </div>
      ))}
    </div>
    {meta && meta.touched && meta.error && (
      <span className="radio-btn-error-message">{meta.error}</span>
    )}
  </div>
);

RadioButtonGroup.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      radioValue: PropTypes.number,
    }),
  ).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

RadioButtonGroup.defaultProps = {
  className: '',
  meta: {},
};

export default RadioButtonGroup;
