import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  sortableContainer,
  sortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = SortableHandle(() => (
  <span className="handle">
    <i className="fas fa-grip-vertical" />
  </span>
));

const SortableItem = sortableElement(({ value, idx }) => (
  <li className="drag-list-item">
    <div className="drag-list-item__text">
      <span className="order">{idx + 1}.</span>
      <span>{value}</span>
    </div>{' '}
    <DragHandle />
  </li>
));

const SortableContainer = sortableContainer(({ children }) => (
  <ul className="drag-list">{children}</ul>
));

const DragList = ({ items, updateItems }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateItems(arrayMoveImmutable(items, oldIndex, newIndex));
  };

  return (
    <Fragment>
      <SortableContainer onSortEnd={onSortEnd}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.value}`}
            index={index}
            idx={index}
            value={value.label}
          />
        ))}
      </SortableContainer>
    </Fragment>
  );
};

DragList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  updateItems: PropTypes.func.isRequired,
};

export default DragList;
