import { BaseModel } from 'sjs-base-model';

export default class ProfileModel extends BaseModel {
  userId = '';

  firstName = '';

  lastName = '';

  email = '';

  ageGroup = '';

  country = '';

  employmentStatus = '';

  currentEmploymentExperience = '';

  jobTitle = '';

  careerField = '';

  careerStages = [];

  userValues = {};

  linkedInProfileUrl = '';

  education = '';

  workExperience = '';

  importantGoals = [];

  managingEmployeeProblems = [];

  workHabits = [];

  workProgress = [];

  preferredWorkType = [];

  checkIns = [];

  currentPage = 1;

  onboardingStatus = '';

  githubOrPersonalWebsiteUrl = '';

  createdAt = '';

  updatedAt = '';

  constructor(data) {
    super();

    this.update(data);
  }
}
