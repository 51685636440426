import React from 'react';
import { Container } from 'reactstrap';

const NotfoundPage = () => (
  <Container className="onboarding__survey__container">
    <div className="welcome-page-container">
      <div className="welcome-page-container__card confirm-page-container-card thank_you_page">
        <h3 className="text-center mb-40 confirm-page-text">
          Oops... Page not found
        </h3>
        <p className="text-center text-large">
          This page is dedicated to Enployable&apos;s intelligent matching
          process. Please go to{' '}
          <a href="https://enployable.com">enployable.com</a> or contact{' '}
          <a href="mailto:hello@enployable.com">hello@enployable.com</a> for
          further assistance. Thanks!
        </p>
      </div>
    </div>
  </Container>
);

export default NotfoundPage;
