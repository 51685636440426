import React, { useState } from 'react';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';

const Layout = () => {
  const [sidebar, setSidebar] = useState({
    show: false,
    collapse: false,
  });

  const sidebarVisibility = () => {
    setSidebar({
      ...sidebar,
      collapse: !sidebar.collapse,
    });
  };

  const mobileSidebarVisibility = () => {
    setSidebar({
      ...sidebar,
      show: !sidebar.show,
    });
  };

  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
    'layout--top-navigation': false,
  });

  return (
    <div className={layoutClass}>
      <Topbar
        changeMobileSidebarVisibility={mobileSidebarVisibility}
        changeSidebarVisibility={sidebarVisibility}
        topbarButtonVisibility={false}
      />
    </div>
  );
};

// Layout.propTypes = {
//   dispatch: PropTypes.func.isRequired,
// };

export default Layout;
