/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import RadioButtonGroup from './RadioButtonGroup';
import config from '../../../shared/config';
import validate from './validate';
import renderSelectField from '../../../shared/components/form/Select';

const WizardFormZero = ({ handleSubmit }) => (
  <form
    className="form form--horizontal wizard__form form__pre-screen-questions"
    onSubmit={handleSubmit}
  >
    {/* <div className="form__form-group">
      <div className="wizard__form__custom__label">
        <span className="wizard__form__label__custom wizard__bold__title">
          Below you’ll see a series of statements about your values and the way
          you work. Please rate the extent to which you agree or disagree with
          each of them
        </span>
      </div>
    </div> */}
    {/* <div key={`index_${item.question}`}>
        <div className="wizard__form__title-new-line wizard__form__label__custom wizard__bold__title">
          <span>{item.question}*</span>
        </div>
        <Field
          name={`user_value__${item.value}`}
          component={RadioButtonGroup}
          items={config.surveyConfig.userValuesOptions.map((opt) => ({
            ...opt,
            name: `user_value__${item.value}`,
          }))}
          className="wizard__form__label__custom"
        />
      </div> */}
    <div className="form__form-group">
      <span className="wizard__form__title-new-line wizard__form__label__custom wizard__bold__title">
        Are you currently open to new employment opportunities?*
      </span>
      <div className="form__form-group-field">
        <Field
          name="openForNewOpportunities"
          component={RadioButtonGroup}
          items={config.surveyConfig.yesNoAnswerRadio.map((opt) => ({
            ...opt,
            name: 'openForNewOpportunities',
          }))}
          className="wizard__form__label__custom"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="wizard__form__title-new-line wizard__form__label__custom wizard__bold__title">
        Do you possess an active Professional Engineer [PE] license?*
      </span>
      <div className="form__form-group-field">
        <Field
          name="activeProfessionalEngineerLicense"
          component={RadioButtonGroup}
          items={config.surveyConfig.yesNoAnswerRadio.map((opt) => ({
            ...opt,
            name: 'activeProfessionalEngineerLicense',
          }))}
          className="wizard__form__label__custom"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="wizard__form__title-new-line wizard__form__label__custom wizard__bold__title">
        Are you willing to travel (10-25%)?*
      </span>
      <div className="form__form-group-field">
        <Field
          name="willingToTravel"
          component={RadioButtonGroup}
          items={config.surveyConfig.yesNoAnswerRadio.map((opt) => ({
            ...opt,
            name: 'willingToTravel',
          }))}
          className="wizard__form__label__custom"
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button
        color="primary"
        type="button"
        className="previous wizard__form__button__custom"
        disabled
      >
        Back
      </Button>
      <Button
        color="primary"
        type="submit"
        className="next wizard__form__button__custom"
      >
        Next
      </Button>
    </ButtonToolbar>
  </form>
);

WizardFormZero.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'userInfo', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WizardFormZero);
