import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import RequestingReducer from './requesting/RequestingReducer';
import ErrorReducer from './error/ErrorReducer';
import ProfileReducer from './profile/ProfileReducer';

export default () => {
  const reducerMap = {
    form: reduxFormReducer,
    error: ErrorReducer.reducer,
    requesting: RequestingReducer.reducer,
    profile: new ProfileReducer().reducer,
  };

  return combineReducers(reducerMap);
};
