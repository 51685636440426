/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Row, CardBody, Container } from 'reactstrap';
import { connect } from 'react-redux';

import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import WizardFormThree from './WizardFormThree';
import WelcomePage from './WelcomePage';
import config from '../../../shared/config';
import countryList from '../../../shared/config/country.json';
import WizardFormZero from './WizardFormZero';

const WizardForm = ({ profile, file, onSubmit }) => {
  const [page, setPage] = useState(1);
  const [initialValues, setInitialValues] = useState({});

  const nextPage = () => {
    setPage(page + 1);

    // if (!userInput.target) {
    //   setInitialValues(userInput);
    //   // on every step send the data to backend
    //   const data = { ...userInput };
    //   data.currentPage = page + 1;
    //   onSubmit(data);
    // }
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  // last submit
  const submit = (values) => {
    const data = { ...values };
    setInitialValues({});
    data.currentPage = page;
    onSubmit(data, true);
  };

  const populateFieldValues = (values) => {
    let data = { ...values };
    for (const key in values) {
      if (values[key]) {
        if (key === 'ageGroup') {
          data[key] = config.surveyConfig.ageGroupOptions.find(
            (ageGroup) => ageGroup.value === values[key],
          );
        }
        if (key === 'careerField') {
          data[key] = config.surveyConfig.careerFieldOptions.find(
            (careerField) => careerField.value === values[key],
          );
        }
        if (key === 'country') {
          data[key] = countryList.find(
            (country) => country.value === values[key],
          );
        }
        if (key === 'currentEmploymentExperience') {
          data[key] =
            config.surveyConfig.currentEmploymentExperienceOptions.find(
              (currentEmploymentExperience) =>
                currentEmploymentExperience.value === values[key],
            );
        }
        if (key === 'employmentStatus') {
          data[key] = config.surveyConfig.employmentStatusOptions.find(
            (employmentStatus) => employmentStatus.value === values[key],
          );
        }
        if (key === 'careerStages') {
          data[key] = config.surveyConfig.careerStagesOptions.filter(
            (careerStages) => {
              for (const stage of values[key]) {
                if (stage === careerStages.value) {
                  return true;
                }
              }
              return false;
            },
          );
        }
        if (key === 'education') {
          data[key] = config.surveyConfig.collegeDegree.find(
            (education) => education.value === values[key],
          );
        }
        if (key === 'workExperience') {
          data[key] = config.surveyConfig.workExperience.find(
            (workExperience) => workExperience.value === values[key],
          );
        }
        if (key === 'userValues') {
          data = {
            ...data,
            ...Object.assign(
              {},
              ...Object.keys(values[key]).map((k) => ({
                [`user_value__${k}`]: values[key][k],
              })),
            ),
          };
        }
        if (
          key === 'importantGoals' ||
          key === 'managingEmployeeProblems' ||
          key === 'workHabits' ||
          key === 'workProgress' ||
          key === 'preferredWorkType' ||
          key === 'checkIns'
        ) {
          data[`user_value__${key}`] = values[key];
        }
      }
    }
    data.resumeFile = file;
    return data;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    setPage(profile.currentPage || 1);
    setInitialValues(populateFieldValues(profile));
  }, [profile]);

  return (
    <Fragment>
      {page === 1 && (
        <Container className="welcome__container">
          <WelcomePage onSubmit={nextPage} />
        </Container>
      )}
      {page === 2 && (
        <Container className="pre_screen__container">
          <WizardFormZero
            onSubmit={nextPage}
            initialValues={initialValues}
          />
        </Container>
      )}
      {page !== 1 && page !== 2 && (
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody className="wizard">
                <div className="wizard__steps">
                  <div
                    className={`wizard__step${
                      page === 3 ? ' wizard__step--active' : ''
                    }`}
                  >
                    <p className="wizard__steps__tab">About You</p>
                  </div>
                  <div
                    className={`wizard__step${
                      page === 4 ? ' wizard__step--active' : ''
                    }`}
                  >
                    <p className="wizard__steps__tab">Your Values</p>
                  </div>
                  <div
                    className={`wizard__step${
                      page === 5 ? ' wizard__step--active' : ''
                    }`}
                  >
                    <p className="wizard__steps__tab">Your Priority</p>
                  </div>
                </div>
                <div className="wizard__form-wrapper">
                  {page === 3 && (
                    <WizardFormOne
                      previousPage={previousPage}
                      onSubmit={nextPage}
                      initialValues={initialValues}
                    />
                  )}
                  {page === 4 && (
                    <WizardFormTwo
                      previousPage={previousPage}
                      onSubmit={nextPage}
                      initialValues={initialValues}
                    />
                  )}
                  {page === 5 && (
                    <WizardFormThree
                      previousPage={previousPage}
                      onSubmit={submit}
                      initialValues={initialValues}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

WizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    userId: PropTypes.string,
    ageGroup: PropTypes.string,
    country: PropTypes.string,
    employmentStatus: PropTypes.string,
    careerField: PropTypes.string,
    currentEmploymentExperience: PropTypes.string,
    jobTitle: PropTypes.string,
    interestedCompanies: PropTypes.arrayOf(PropTypes.string),
    careerStages: PropTypes.arrayOf(PropTypes.string),
    interestedServices: PropTypes.arrayOf(PropTypes.string),
    userValues: PropTypes.shape({
      advancement: PropTypes.number,
      benefits: PropTypes.number,
      compensation: PropTypes.number,
      diversity: PropTypes.number,
      growth: PropTypes.number,
      mission: PropTypes.number,
      stability: PropTypes.number,
      teamwork: PropTypes.number,
      work_life_balance: PropTypes.number,
    }),
    linkedInProfileUrl: PropTypes.string,
    education: PropTypes.string,
    workExperience: PropTypes.string,
    currentPage: PropTypes.number,
    onboardingStatus: PropTypes.string,
    githubOrPersonalWebsiteUrl: PropTypes.string,
  }).isRequired,
  file: PropTypes.shape({
    originalFileName: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
  file: state.profile.file,
});

export default connect(mapStateToProps)(WizardForm);
