export default {
  surveyConfig: {
    ageGroupOptions: [
      { value: '<18', label: 'Below 18' },
      { value: '18-29', label: '18-29' },
      { value: '30-45', label: '30-45' },
      { value: '46-59', label: '46-59' },
      { value: '60-75', label: '60-75' },
      { value: '>75', label: 'Above 75' },
    ],
    employmentStatusOptions: [
      { value: 'full-time', label: 'Full Time Employee' },
      { value: 'part-time', label: 'Part Time Employee' },
      { value: 'self', label: 'Self Employed' },
      { value: 'startup-founder', label: 'Startup Founder' },
      { value: 'contract-worker', label: 'Contract Worker' },
      { value: 'student', label: 'Student' },
      { value: 'in-between-jobs', label: 'In Between Jobs' },
      { value: 'retired', label: 'Retired' },
      { value: 'none', label: 'None of the above' },
    ],
    currentEmploymentExperienceOptions: [
      { value: '<1', label: 'Less than 1 year' },
      { value: '1-3', label: '1 to 3 years' },
      { value: '3-5', label: '3 to 5 years' },
      { value: '>5', label: 'More than 5 years' },
    ],
    careerFieldOptions: [
      {
        value: 'architecture, planning, & environmental design',
        label: 'Architecture, Planning, & Environmental Design',
      },
      { value: 'finance', label: 'Finance' },
      {
        value: 'arts, culture & entertainment',
        label: 'Arts, Culture & Entertainment',
      },
      { value: 'business', label: 'Business' },
      { value: 'communications', label: 'Communications' },
      { value: 'education', label: 'Education' },
      {
        value: 'engineering & computer science',
        label: 'Engineering & Computer Science',
      },
      { value: 'government', label: 'Government' },
      { value: 'health & medicine', label: 'Health & Medicine' },
      { value: 'international', label: 'International' },
      { value: 'law & public policy', label: 'Law & Public Policy' },
      {
        value: 'biological and physical sciences',
        label: 'Biological and Physical Sciences',
      },
      {
        value: 'social impact/community service',
        label: 'Social Impact/Community Service',
      },
      { value: 'none of the above', label: 'None of the Above' },
    ],
    careerStagesOptions: [
      {
        value: 'I just graduated and I’m new to the job market',
        label: 'I just graduated and I’m new to the job market',
      },
      {
        value: 'I’m transitioning into a new career',
        label: 'I’m transitioning into a new career',
      },
      {
        value: 'I’m currently employed, but I want to move to the next level',
        label: 'I’m currently employed, but I want to move to the next level',
      },
      {
        value: 'I want to start my business',
        label: 'I want to start my business',
      },
      {
        value: 'I already have a business',
        label: 'I already have a business',
      },
      {
        value: 'I’m retired, and I want to stay active in the workspace',
        label: 'I’m retired, and I want to stay active in the workspace',
      },
      { value: 'Other', label: 'Other' },
    ],
    interestedServicesOptions: [
      {
        value: 'Tailored job recommendations',
        label: 'Tailored job recommendations',
      },
      { value: 'Tailored job resources', label: 'Tailored job resources' },
      { value: 'Job application prep', label: 'Job application prep' },
      { value: 'Community support', label: 'Community support' },
      {
        value: 'Being introduced with a potential employer',
        label: 'Being introduced to a potential employer',
      },
    ],
    userValuesQuestions: [
      {
        value: 'compensation',
        question: '1. High compensation is important to me.',
      },
      {
        value: 'benefits',
        question:
          '2. Good benefits (such as health insurance and 401k) are an important factor when I decide whether or not I would accept a new job.',
      },
      {
        value: 'diversity',
        question: '3. Diversity is important to me',
      },
      // {
      //   value: 'mission',
      //   question:
      //     '4. Whether or not I identify with my company’s mission statement is an important factor when I decide whether or not I would accept a new job.',
      // },
      {
        value: 'growth',
        question:
          '4. Whether or not a company has a lot of growth potential is important for me.',
      },
      {
        value: 'advancement',
        question:
          '5. In general, I care more about opportunities for advancement than my work-life balance.',
      },
      {
        value: 'teamwork',
        question:
          '6. I enjoy working in teams, even if that means I sometimes have to make a compromise in order to keep the teams together.',
      },
      {
        value: 'stability',
        question:
          '7. Having a stable paycheck is important to me.  I may opt for a company that has a lot of stability but has limited opportunities for a promotion.',
      },
      {
        value: 'work_life_balance',
        question: '8. Work-life balance is important to me.',
      },
    ],
    userValuesOptions: [
      {
        label: 'Strongly Disagree',
        radioValue: 1,
      },
      {
        label: 'Disagree',
        radioValue: 2,
      },
      {
        label: 'Neutral',
        radioValue: 3,
      },
      {
        label: 'Agree',
        radioValue: 4,
      },
      {
        label: 'Strongly Agree',
        radioValue: 5,
      },
    ],
    userValuesMap: {
      advancement: 'advancement',
      benefits: 'benefits',
      compensation: 'compensation',
      diversity: 'diversity',
      growth: 'growth',
      // mission: 'mission',
      stability: 'stability',
      teamwork: 'teamwork',
      work_life_balance: 'work-life balance',
    },
    collegeDegree: [
      {
        label: "Bachelor's degree",
        value: 'bachelor_degree',
      },
      {
        label: "Master's degree",
        value: 'master_degree',
      },
      {
        label: 'Doctoral degree',
        value: 'doctoral_degree',
      },
      {
        label: 'No related degree',
        value: 'no_degree',
      },
    ],
    workExperience: [
      {
        label: 'None',
        value: 'no_experience',
      },
      {
        label: '1-5 years',
        value: '1_5_years',
      },
      {
        label: '5-10 years',
        value: '5_10_years',
      },
      {
        label: 'More than 10 years',
        value: 'more_than_10_years',
      },
    ],
    rankedQuestionsOptions: {
      importantGoals: [
        {
          label: 'Making sure that I hit my deadlines / goals',
          value: 'monthly_goals',
        },
        {
          label: 'Maintaining good relationships with my teammates',
          value: 'keeping_investors_happy',
        },
        {
          label: 'Keeping my customers happy',
          value: 'hiring',
        },
        {
          label: 'Improving my technical knowledge and skills',
          value: 'on_time_release',
        },
        {
          label: 'Acquiring new customers',
          value: 'new_features',
        },
      ],
      preferredWorkType: [
        {
          label:
            'A high-growth organization with more upside potential but employees are expected to work overtime',
          value: 'work_overtime',
        },
        {
          label:
            'Predictable workload with limited upside potential but with a good work-life balance',
          value: 'work_life_balance',
        },
      ],
      managingEmployeeProblems: [
        {
          label:
            'Ask for a pay raise so you have more incentive to work harder',
          value: 'increase_compensation',
        },
        {
          label:
            'Request that some of your workload be transferred to another employee',
          value: 'reduce_workload',
        },
        {
          label:
            'Have a discussion with your supervisor about the possible solutions to this problem',
          value: 'discussion_on_work_habits',
        },
        {
          label: 'Look for another job',
          value: 'terminate_and_replace',
        },
      ],
      workHabits: [
        {
          label:
            'I tend to produce large volumes of work rather than slowing down to worry about every detail',
          value: 'large_volume_work_with_risk_of_mistakes',
        },
        {
          label: 'I enjoy working in a team environment',
          value: 'good_team_member',
        },
        {
          label: 'I tend to take on extra responsibility',
          value: 'willingness_to_take_extra_responsibility',
        },
        {
          label:
            'I tend to spend more time to produce meticulous and accurate work',
          value: 'ability_to_produce_quality_work_with_extra_time',
        },
      ],
      checkIns: [
        {
          label:
            'My current work focuses on management rather than the day-to-day technical details',
          value: 'frequent_check_in_with_team_lead',
        },
        {
          label: 'I spend most of my day working on technical details ',
          value: 'challenge_intructions_from_supervisors',
        },
      ],
      workProgress: [
        {
          label: 'Submission of weekly status reports',
          value: 'weekly_status_reports',
        },
        {
          label: 'Weekly in-person meetings between employee and supervisor',
          value: 'weekly_meetings',
        },
        {
          label: 'Only report when a work assignment has been completed',
          value: 'nothing_until_deadline',
        },
      ],
    },
    yesNoAnswerRadio: [
      {
        label: 'Yes',
        radioValue: true,
      },
      {
        label: 'No',
        radioValue: false,
      },
    ],
  },
  jobConfig: {
    typeOfWork: [
      { value: 'Full-time', label: 'Full-time' },
      { value: 'hourly/parttime', label: 'Hourly / Part-time' },
      { value: 'contract/freelance', label: 'Contract / Freelance' },
      { value: 'internship', label: 'Internship' },
    ],
    howManyHoursPartTime: [
      { value: '<10h-week', label: 'Less than 10 hours a week' },
      { value: '10-25h-week', label: '10-25 hours per week' },
      { value: '>25h-week', label: 'More than 25 hours per week' },
      { value: 'na', label: 'Not Applicable' },
    ],
    whenToFillInThisPosition: [
      { value: '<2-weeks', label: 'Less than 2 weeks' },
      { value: '2-4-weeks', label: '2-4 weeks' },
      { value: '>4-weeks', label: 'More than 4 weeks' },
    ],
    jobLocation: [
      { value: 'remote', label: 'Remote' },
      { value: 'on-location', label: 'On Location' },
      { value: 'on-multiple-locations', label: 'On Multiple Locations' },
      { value: 'on-the-road', label: 'On The Road' },
    ],
    jobRole: [
      { value: 'frontend', label: 'Frontend' },
      { value: 'backend', label: 'Backend' },
      { value: 'fullstack', label: 'Fullstack' },
      { value: 'devops', label: 'Devops' },
      { value: 'cloud', label: 'Cloud' },
      { value: 'networking', label: 'Networking' },
      {
        value: 'machine-learning-engineer',
        label: 'Machine Learning Engineer',
      },
      { value: 'data-scientist', label: 'Data Scientist' },
    ],
    jobExperience: [
      { value: '<3-years', label: 'Less than 3 years' },
      { value: '3-5-years', label: '3-5 years' },
      { value: '>5-years', label: 'More than 5 years' },
    ],
    otherFormOfCompensation: [
      { value: 'benefits', label: 'Benefits' },
      { value: 'free-lunches', label: 'Free Lunches' },
      { value: 'bonus-pay', label: 'Bonus Pay' },
      { value: 'signing-bonus', label: 'Signing Bonus' },
      { value: 'stock-options', label: 'Stock Options' },
    ],
    educationLevel: [
      { value: 'no-requirement', label: 'No minimum education required' },
      { value: 'high-school', label: 'High school or equivalent' },
      { value: 'college-degree', label: 'College degree' },
      { value: 'master-degree', label: "Master's degree" },
      { value: 'phd', label: 'PhD' },
    ],
  },
};
