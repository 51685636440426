import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';

import '../../scss/app.scss';
import Router from './Router';
import rootStore from '../../stores/rootStore';
import ScrollToTop from './ScrollToTop';

const App = () => {
  const initialState = {};
  const store = rootStore(initialState);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Fragment>
            <div>
              <Router />
            </div>
          </Fragment>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
