import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

export const DropZoneField = ({
  value,
  customHeight,
  name,
  onChange,
  acceptFileTypes,
  bodyTitle,
}) => {
  const files = value;
  const onDrop = (file) => {
    onChange(file);
  };
  const removeFile = (index, e) => {
    e.preventDefault();
    if (files.originalFileName) {
      onChange([]);
    } else {
      onChange(value.filter((val, i) => i !== index));
    }
  };

  return (
    <div
      className={`dropzone dropzone--single${
        customHeight ? ' dropzone--custom-height' : ''
      }`}
    >
      <Dropzone
        accept={acceptFileTypes}
        name={name}
        multiple={false}
        onDrop={(fileToUpload) => {
          onDrop(fileToUpload);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone__input">
            {((!Array.isArray(files) && !files.originalFileName) ||
              files.length === 0) && (
              <div className="dropzone__drop-here dropzone__custom dropzone__drop-here__upload-text">
                <span className="fas fa-upload" /> {bodyTitle}
              </div>
            )}
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      {files && Array.isArray(files) && files.length > 0 && (
        <aside className="dropzone__img">
          <p className="dropzone__img-name dropzone__custom">{files[0].name}</p>
          <button
            className="dropzone__img-delete"
            type="button"
            onClick={(e) => removeFile(0, e)}
          >
            Remove
          </button>
        </aside>
      )}
      {files && files.originalFileName && (
        <aside className="dropzone__img">
          <p className="dropzone__img-name dropzone__custom">
            {files.originalFileName}
          </p>
          <button
            className="dropzone__img-delete"
            type="button"
            onClick={(e) => removeFile(0, e)}
          >
            Remove
          </button>
        </aside>
      )}
    </div>
  );
};

DropZoneField.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  customHeight: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      originalFileName: PropTypes.string,
    }),
  ]).isRequired,
  acceptFileTypes: PropTypes.string,
  bodyTitle: PropTypes.string,
};

DropZoneField.defaultProps = {
  customHeight: false,
  acceptFileTypes:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/docx, application/pdf',
  bodyTitle: 'Upload here',
};

const renderDropZoneField = ({
  input,
  customHeight,
  acceptFileTypes,
  bodyTitle,
  meta,
}) => (
  <div className="dropzone-wrapper">
    <DropZoneField
      {...input}
      acceptFileTypes={acceptFileTypes}
      customHeight={customHeight}
      bodyTitle={bodyTitle}
    />
    {meta.touched && meta.error && (
      <span className="form__form-group-error">{meta.error}</span>
    )}
  </div>
);

renderDropZoneField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  customHeight: PropTypes.bool,
  acceptFileTypes: PropTypes.string,
  bodyTitle: PropTypes.string,
};

renderDropZoneField.defaultProps = {
  customHeight: false,
  acceptFileTypes:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/docx, application/pdf',
  bodyTitle: 'Upload here',
  meta: {},
};

export default renderDropZoneField;
