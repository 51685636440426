/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
  topbarButtonVisibility,
}) => (
  <div className="topbar">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        {topbarButtonVisibility && (
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeMobileSidebarVisibility}
            changeSidebarVisibility={changeSidebarVisibility}
          />
        )}
        <a
          className={`topbar__logo ${
            topbarButtonVisibility ? '' : 'topbar__logo__margin'
          }`}
          href="https://enployable.com"
          target="_blank"
          rel="noreferrer"
        />
      </div>
    </div>
  </div>
);

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
  topbarButtonVisibility: PropTypes.bool.isRequired,
};

export default Topbar;
