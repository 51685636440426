/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Wizard from './components/WizardForm';
import ProfileAction from '../../stores/profile/ProfileAction';
import ConfirmationPage from './components/ConfirmationPage';

const UserInfoSurveyForm = ({ dispatch, profile, history, location }) => {
  const [confirmationPage, setConfirmationPage] = useState(false);

  // parse url and get query params
  const queryParams = new URLSearchParams(location.search);

  const [companyId] = useState(queryParams.get('companyId'));
  const [screeningId] = useState(queryParams.get('screeningId'));
  const [surveyId] = useState(queryParams.get('surveyId'));

  useEffect(() => {
    if (!companyId || !screeningId || !surveyId) {
      history.push('/notfound');
    }
  }, [companyId, screeningId, surveyId, history]);

  useEffect(() => {
    const navigate = () => {
      if (
        !confirmationPage &&
        profile &&
        profile.onboardingStatus === 'COMPLETED'
      ) {
        history.push('/');
      }
    };
    navigate();
  }, [profile]);

  const saveProfile = (userInput, last) => {
    const data = {};
    const userValues = {};
    for (const key in userInput) {
      if (
        key !== 'resumeFile' &&
        typeof userInput[key] === 'object' &&
        userInput[key] !== null
      ) {
        if (Array.isArray(userInput[key]) && !key.startsWith('user_value__')) {
          data[key] = userInput[key].map((val) => val?.value || val);
        } else if (key.startsWith('user_value__')) {
          data[key.replace('user_value__', '')] = userInput[key];
        } else {
          data[key] = userInput[key].value;
        }
      } else if (key.startsWith('user_value__')) {
        if (userInput[key]) {
          userValues[key.replace('user_value__', '')] = userInput[key];
        }
      } else {
        data[key] = userInput[key];
      }
    }
    data.userValues = userValues;
    data.companyId = companyId;
    data.screeningId = screeningId;
    data.surveyId = surveyId;

    if (last) {
      setConfirmationPage(true);
    }

    dispatch(ProfileAction.saveProfile(data));
  };

  if (
    !(confirmationPage || (profile && profile.onboardingStatus === 'COMPLETED'))
  ) {
    return (
      <Container>
        <Wizard onSubmit={saveProfile} />
      </Container>
    );
  }

  return (
    <Container className="onboarding__survey__container">
      <ConfirmationPage />
    </Container>
  );
};

UserInfoSurveyForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    onboardingStatus: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
  dispatch: state.dispatch,
});

export default withRouter(connect(mapStateToProps)(UserInfoSurveyForm));
