const validate = (values) => {
  const errors = {};
  const requiredFields = [
    'openForNewOpportunities',
    'activeProfessionalEngineerLicense',
    'willingToTravel',
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'ageGroup',
    'country',
    'resumeFile',
    // 'linkedInProfileUrl',
    'files',
    'education',
    'employmentStatus',
    'jobTitle',
    'careerField',
    'careerStages',
    'workExperience',
    'currentEmploymentExperience',
    'user_value__compensation',
    'user_value__benefits',
    'user_value__diversity',
    // 'user_value__mission',
    'user_value__growth',
    'user_value__advancement',
    'user_value__teamwork',
    'user_value__stability',
    'user_value__work_life_balance',
  ];
  requiredFields.forEach((field) => {
    if (values[field] === null || values[field] === undefined) {
      errors[field] = 'This field is required';
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }
  if (
    values.linkedInProfileUrl &&
    !/^((http|https):\/\/|www)[^ "]+$/i.test(values.linkedInProfileUrl)
  ) {
    errors.linkedInProfileUrl = 'Invalid URL';
  }
  if (
    values.githubOrPersonalWebsiteUrl &&
    !/^((http|https):\/\/|www)[^ "]+$/i.test(values.githubOrPersonalWebsiteUrl)
  ) {
    errors.githubOrPersonalWebsiteUrl = 'Invalid URL';
  }
  if (
    values.resumeFile &&
    (values.resumeFile.length === 0 ||
      Object.keys(values.resumeFile).length === 0)
  ) {
    errors.resumeFile = 'Please upload your resume';
  }
  if (values.files && values.files.length === 0) {
    errors.files = 'Please upload your resume';
  }
  if (values.interestedCompanies && values.interestedCompanies.length === 0) {
    errors.interestedCompanies = 'Please select 4 companies';
  }
  if (values.interestedCompanies && values.interestedCompanies.length > 4) {
    errors.interestedCompanies = 'Please select only 4 companies';
  }
  if (values.phoneNumber) {
    const phone = values.phoneNumber.trim();
    if (!/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(phone)) {
      errors.phoneNumber = 'Invalid phone number';
    }
  }
  return errors;
};

export default validate;
