import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const MainWrapper = ({ children }) => (
  <div className="theme-light ltr-support" dir="ltr">
    <div className="wrapper">{children}</div>
  </div>
);

MainWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default withRouter(MainWrapper);
