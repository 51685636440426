import BaseReducer from '../../utilities/BaseReducer';
import ProfileAction from './ProfileAction';

export default class ProfileReducer extends BaseReducer {
  initialState = {
    profile: {},
    file: {},
  };

  [ProfileAction.GET_PROFILE_FINISHED](state, action) {
    return {
      ...state,
      profile: action.payload.profile,
      file: action.payload.file,
    };
  }

  [ProfileAction.PROFILE_CREATE_FINISHED](state, action) {
    return {
      ...state,
      profile: action.payload.profile,
      file: action.payload.file,
    };
  }
}
