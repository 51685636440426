import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import WebAnalyticsService from './services/WebAnalyticsService';
import environment from './environments';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';

function render() {
  ReactDOM.render(
    <React.Fragment>
      <App />
    </React.Fragment>,
    document.getElementById('root'),
  );
}
render();

try {
  ReactGA.initialize(environment.GOOGLE_ANALYTICS_MEASUREMENT_ID);
  ReactGA.send('pageview');
} catch (err) {
  console.log(err);
}

WebAnalyticsService.initialize(window.location.search);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
