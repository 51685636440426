import ActionUtility from '../../utilities/ActionUtility';
import ProfileEffect from './ProfileEffect';

export default class ProfileAction {
  static GET_PROFILE = 'ProfileAction.GET_PROFILE';

  static GET_PROFILE_FINISHED = 'ProfileAction.GET_PROFILE_FINISHED';

  static PROFILE_CREATE = 'ProfileAction.PROFILE_CREATE';

  static PROFILE_CREATE_FINISHED = 'ProfileAction.PROFILE_CREATE_FINISHED';

  static getProfile() {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProfileAction.GET_PROFILE,
        ProfileEffect.getProfile,
      );
    };
  }

  static saveProfile(userData) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ProfileAction.PROFILE_CREATE,
        ProfileEffect.saveProfile,
        userData,
      );
    };
  }
}
