import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import renderSelectField from '../../../shared/components/form/Select';
import countryList from '../../../shared/config/country.json';
import config from '../../../shared/config';
import renderDropZoneField from '../../../shared/components/form/DropZone';
import renderMultiSelectField from '../../../shared/components/form/MultiSelect';
import validate from './validate';
import InputWrapper from './InputWrapper';

const WizardFormOne = ({ handleSubmit, previousPage }) => (
  <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        First Name *
      </span>
      <div className="form__form-group-field">
        <Field
          name="firstName"
          component={InputWrapper}
          type="text"
          placeholder="Enter your first name"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Last Name *
      </span>
      <div className="form__form-group-field">
        <Field
          name="lastName"
          component={InputWrapper}
          type="text"
          placeholder="Enter your last name"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Email *
      </span>
      <div className="form__form-group-field">
        <Field
          name="email"
          component={InputWrapper}
          type="text"
          placeholder="Enter your email"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Phone Number *
      </span>
      <div className="form__form-group-field">
        <Field
          name="phoneNumber"
          component={InputWrapper}
          type="text"
          placeholder="Enter your phone number"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__title wizard__form__label__custom wizard__bold__title">
        Age Group *
      </span>
      <div className="form__form-group-field">
        <Field
          name="ageGroup"
          component={renderSelectField}
          type="text"
          options={config.surveyConfig.ageGroupOptions}
          placeholder="Choose your age group"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__title wizard__form__label__custom wizard__bold__title">
        Country *
      </span>
      <div className="form__form-group-field">
        <Field
          name="country"
          component={renderSelectField}
          type="text"
          options={countryList}
          placeholder="Choose your country"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        LinkedIn Profile Url
      </span>
      <div className="form__form-group-field">
        <Field
          name="linkedInProfileUrl"
          component={InputWrapper}
          type="text"
          placeholder="https://"
        />
      </div>
    </div>
    {/* <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Personal Project/Profile Site (such as Github)
      </span>
      <div className="form__form-group-field">
        <Field
          name="githubOrPersonalWebsiteUrl"
          component={InputWrapper}
          type="text"
          placeholder="https://"
        />
      </div>
    </div> */}
    <div className="form__form-group">
      <span className="form__form-group-label wizard__title wizard__form__label__custom wizard__bold__title">
        Resume *
      </span>
      <div className="form__form-group-field">
        <Field
          name="resumeFile"
          component={renderDropZoneField}
          customHeight
          bodyTitle="Upload your resume here"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Education *
      </span>
      <div className="form__form-group-field">
        <Field
          name="education"
          component={renderSelectField}
          placeholder="Your education"
          options={config.surveyConfig.collegeDegree}
          select
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Employment Status *
      </span>
      <div className="form__form-group-field">
        <Field
          name="employmentStatus"
          component={renderSelectField}
          type="text"
          options={config.surveyConfig.employmentStatusOptions}
          placeholder="Your current employment status"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Job Title *
      </span>
      <div className="form__form-group-field">
        <Field
          name="jobTitle"
          component={InputWrapper}
          type="text"
          placeholder="Enter your job title"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Career Field *
      </span>
      <div className="form__form-group-field">
        <Field
          name="careerField"
          component={renderSelectField}
          type="text"
          options={config.surveyConfig.careerFieldOptions}
          placeholder="Your career field (If you are a student, indicate your desired field)"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Career Stage *
      </span>
      <div className="form__form-group-field">
        <Field
          name="careerStages"
          component={renderMultiSelectField}
          options={config.surveyConfig.careerStagesOptions}
          placeholder="Your career stage"
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Work Experience *
      </span>
      <div className="form__form-group-field">
        <Field
          name="workExperience"
          component={renderSelectField}
          placeholder="Your work experience"
          options={config.surveyConfig.workExperience}
          select
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label wizard__form__label__custom wizard__bold__title">
        Years In Current Position *
      </span>
      <div className="form__form-group-field">
        <Field
          name="currentEmploymentExperience"
          component={renderSelectField}
          type="text"
          options={config.surveyConfig.currentEmploymentExperienceOptions}
          placeholder="Your experience in current position"
        />
      </div>
    </div>

    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button
        color="primary"
        type="button"
        onClick={previousPage}
        className="previous wizard__form__button__custom"
      >
        Back
      </Button>
      <Button
        color="primary"
        type="submit"
        className="next wizard__form__button__custom"
      >
        Next
      </Button>
    </ButtonToolbar>
  </form>
);

WizardFormOne.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'userInfo', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WizardFormOne);
