const dev = {
  BACKEND_URL: 'http://localhost:5050',
  HOST_URL: 'http://localhost:3000',
  GOOGLE_ANALYTICS_MEASUREMENT_ID: '',
};

const prod = {
  BACKEND_URL: 'https://api.employer.enployable.com',
  HOST_URL: 'https://survey.enployable.com',
  GOOGLE_ANALYTICS_MEASUREMENT_ID: '',
};

const config = {
  // Add common config values here
  isDevelopment: process.env.CLIENT_ENV !== 'prod',
  // Default to dev if not set
  ...(process.env.CLIENT_ENV === 'prod' ? prod : dev),
};

export default config;
