import { isMobile } from 'react-device-detect';

class WebAnalyticsService {
  initialize(search) {
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.utmData = {
      utm_source: params.utm_source || 'direct',
      ...(params.utm_campaign && { utm_campaign: params.utm_campaign }),
      ...(params.utm_medium && { utm_medium: params.utm_medium }),
      utm_device: isMobile ? 'mobile' : 'web',
    };

    // save utm data to local storage
    localStorage.setItem('utmData', JSON.stringify(this.utmData));
  }

  getUtmData() {
    // fetch utm data from local storage
    const utmData = JSON.parse(localStorage.getItem('utmData'));
    return utmData;
  }
}

export default new WebAnalyticsService();
