import React from 'react';

const ConfirmationPage = () => (
  <div className="welcome-page-container">
    <div className="welcome-page-container__card confirm-page-container-card thank_you_page">
      <span className="modal__title-icon check-wrap" />
      <h3 className="text-center mb-40 confirm-page-text">Well Done!</h3>
      <p className="text-center text-large">
        Thanks for completing the survey. We’ll let you know if you have
        advanced to the next stage of the application process.
      </p>
      <p className="text-center text-large">Best wishes,</p>
      <p className="text-center text-large">Team Enployable</p>
    </div>
  </div>
);

export default ConfirmationPage;
