import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';

const WelcomePage = ({ onSubmit }) => (
  <div className="welcome-page-container">
    <div className="welcome-page-container__card">
      <h3 className="text-center mb-40">Welcome!</h3>
      <p className="text-center text-large">
        Congratulations on completing the first stage of the job application
        process! We are working with your potential employer for the next stage
        of your application. Please complete this survey - it takes less than 5
        minutes.
      </p>
      <p className="text-center text-large">Best of luck! 🚀</p>
      <ButtonToolbar className="welcome-page-container__card__button-bar">
        <Button
          onClick={onSubmit}
          color="primary"
          type="submit"
          className="next welcome-page-container__card__button"
        >
          Start
        </Button>
      </ButtonToolbar>
    </div>
  </div>
);

WelcomePage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default WelcomePage;
