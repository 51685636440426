/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, ButtonToolbar } from 'reactstrap';
import RadioButtonGroup from './RadioButtonGroup';
import config from '../../../shared/config';
import validate from './validate';

const WizardFormTwo = ({ handleSubmit, previousPage }) => (
  <form className="form form--horizontal wizard__form" onSubmit={handleSubmit}>
    <div className="form__form-group">
      <div className="wizard__form__custom__label">
        <span className="wizard__form__label__custom wizard__bold__title">
          Below you’ll see a series of statements about your values and the way
          you work. Please rate the extent to which you agree or disagree with
          each of them
        </span>
      </div>
    </div>
    {config.surveyConfig.userValuesQuestions.map((item) => (
      <div key={`index_${item.question}`}>
        <div className="wizard__form__radio__title wizard__form__label__custom wizard__bold__title">
          <span>{item.question}*</span>
        </div>
        <Field
          name={`user_value__${item.value}`}
          component={RadioButtonGroup}
          items={config.surveyConfig.userValuesOptions.map((opt) => ({
            ...opt,
            name: `user_value__${item.value}`,
          }))}
          className="wizard__form__label__custom"
        />
      </div>
    ))}
    <ButtonToolbar className="form__button-toolbar wizard__toolbar">
      <Button
        color="primary"
        type="button"
        className="previous wizard__form__button__custom"
        onClick={previousPage}
      >
        Back
      </Button>
      <Button
        color="primary"
        type="submit"
        className="next wizard__form__button__custom"
      >
        Next
      </Button>
    </ButtonToolbar>
  </form>
);

WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'userInfo', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(WizardFormTwo);
